<template >
  <div class="justify-content-between align-items-center content-sidebar px-1 py-1">
    <b-form>
      <b-row>
        <!-- <b-col cols="12" class="title-modal">
          <h5>
            {{ $t('IntegrationPage.CreateObject') }}
          </h5>
        </b-col> -->

        <b-col md="12">
          <b-form-group :label="$t('IntegrationPage.Type')">
            <b-form-input id="name" :value="type.name" :disabled="true" />
          </b-form-group>

          <template v-if="type.name !== 'Data Factory'">
            <b-form-group :label="setLabel()"
              :invalid-feedback="invalidNameState ? $t('NameInvalid') : $t('RequiredField')">
              <b-form-input id="name" v-model="name" :placeholder="$t('TypeHere')" :state="stateName" />
            </b-form-group>
          </template>

          <!-- Azure Data Factory -->
          <template v-if="type.name === 'Data Factory'">
            <b-form-group label="Resource Group" :invalid-feedback="$t('RequiredField')">
              <b-form-input id="name" v-model="resourceGroup" placeholder="resourcegroup" :state="stateResourceGroup" />
            </b-form-group>

            <b-form-group label="Data Factory" :invalid-feedback="$t('RequiredField')">
              <b-form-input id="name" v-model="dataFacotry" :placeholder="$t('IntegrationPage.MyDataFactory')"
                :state="stateDataFactory" />
            </b-form-group>

            <b-form-group label="Pipeline"
              :invalid-feedback="invalidNameState ? $t('NameInvalid') : $t('RequiredField')">
              <b-form-input id="name" v-model="name" placeholder="pipeline_data_factory" :state="stateName" />
            </b-form-group>
          </template>

          <!-- Api Restful -->
          <template v-if="type.name == 'REST'">
            <b-form-group :label="$t('IntegrationPage.RelativeURL')" :invalid-feedback="$t('RequiredField')">
              <b-form-input id="url-relativa" v-model="urlRelativa" :placeholder="$t('TypeHere')" />
            </b-form-group>

            <b-form-group :label="$t('IntegrationPage.Method')" :invalid-feedback="$t('RequiredField')">
              <b-form-select id="input-method" v-model="method" :state="stateMethod"
                :options="[{ value: null, text: $t('Select'), disabled: true }, { text: 'POST', value: 'POST' }, { text: 'GET', value: 'GET' },]" />
            </b-form-group>
          </template>

          <!-- Método POST -->
          <template v-if="type.name == 'REST' && method == 'POST'">
            <b-form-group label="Json" class="form-group-json">
              <b-form-input id="input-json" class="input-json" v-model="json" :placeholder="$t('TypeHere')" />
              <div class="btn-details" @click="atualizaJson">
                <ImgDetailsIcon />
              </div>
            </b-form-group>
          </template>
        </b-col>

        <template v-if="type.name == 'REST' && method">
          <b-col md="12" class="p-0 object" v-for="(item, index) in authenticationList" :key="index">
            <div class="d-flex flex-column flex-sm-row">
              <b-col class="col-12 col-sm-5">
                <b-form-group label-for="key" :invalid-feedback="$t('RequiredField')">
                  <span class="d-flex align-items-center label-key">{{ $t('Key') }} <ImgEditIcon class="icon-edit-key">
                    </ImgEditIcon></span>
                  <b-form-input v-model="authenticationList[index].key" :placeholder="$t('TypeHere')"
                    :state="authenticationList[index].stateKey" />

                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-5 ">
                <b-form-group label-for="value" :invalid-feedback="$t('RequiredField')">
                  <span class="d-flex align-items-center label-value">{{ $t('Value') }}
                    <ImgEditIcon class="icon-edit-value" />
                  </span>
                  <b-form-input v-model="authenticationList[index].value" :placeholder="$t('TypeHere')"
                    :state="authenticationList[index].stateValue" />

                </b-form-group>

              </b-col>
              <b-col class="d-sm-flex col-12 col-sm-2 p-0">
                <div class=" d-flex" v-if="(authenticationList.length - 1) == index">
                  <div class="button-action">
                    <b-button class="button-add-icon" @click="newRowAuthentication()">
                      <ImgAddIcon></ImgAddIcon>
                    </b-button>
                  </div>
                </div>
                <div class=" d-flex justify-content-start pr-0">
                  <div class="button-action">
                    <b-button class="button-remove-icon" @click="authenticationList.splice(index, 1)"
                      v-if="authenticationList.length > 1">
                      <ImgDeleteIcon></ImgDeleteIcon>
                    </b-button>
                  </div>
                </div>
              </b-col>
            </div>
          </b-col>
        </template>

        <template v-if="type.name == 'REST' && method">
          <b-col md="12">
            <div class="border-btn-visualizar">
              <button v-if="!sendPreviewData" class="btn-visualizar" type="button" @click="previewData()" :disabled="method == 'POST' && !json">
                {{ $t('IntegrationPage.Preview') }}
              </button>

              <button v-if="sendPreviewData" class="btn-visualizar" disabled type="button" @click="previewData()">
                <b-spinner small />
                {{ $t('IntegrationPage.Preview') }}
              </button>

              <b-form-group :label="$t('IntegrationPage.CollectionReference')" v-if="jsonValid && (method == 'GET' || (method == 'POST' && json)) && previewDataJson">
                <b-form-select id="input-method" v-model="objectReference" :options="collectionReference" />
              </b-form-group>

              <div class="border-campos" v-if="jsonValid && method == 'POST' && !json">
                <div class="header-campos">
                  <span>{{ $t('IntegrationPage.AddFields') }}</span>
                  <!-- <ImgAddIcon v-if="!AddFields" @click="AddFields = !AddFields" />

                  <ImgLessIcon v-else @click="AddFields = !AddFields" /> -->
                </div>

                <template v-if="AddFields">
                  <b-col md="12" class="p-0 object" v-for="(item, index) in listaCampos" :key="index">
                    <div class="d-flex flex-column flex-sm-row">
                      <b-col class="col-12 col-sm-5">
                        <b-form-group label-for="key" :invalid-feedback="$t('RequiredField')">
                          <span class="d-flex align-items-center label-key">{{ $t('IntegrationPage.ColumnName') }}</span>
                          <b-form-input v-model="listaCampos[index].key" :placeholder="$t('TypeHere')"
                            :state="listaCampos[index].stateKey" />
                          
                        </b-form-group>
                      </b-col>

                      <b-col class="col-12 col-sm-5">
                        <b-form-group label-for="value" :invalid-feedback="$t('RequiredField')">
                          <span class="d-flex align-items-center label-value">{{ $t('IntegrationPage.Type') }}</span>

                          <b-form-select id="type-add-field" v-model="listaCampos[index].value" :state="listaCampos[index].stateValue"
                            :options="typeList" />

                        </b-form-group>
                      </b-col>

                      <b-col class="d-sm-flex col-12 col-sm-2 p-0">
                        <div class=" d-flex" v-if="(listaCampos.length - 1) == index">
                          <div class="button-action">
                            <b-button class="button-add-icon" @click="adicionaCampos()">
                              <ImgAddIcon></ImgAddIcon>
                            </b-button>
                          </div>
                        </div>
                        <div class=" d-flex justify-content-start pr-0">
                          <div class="button-action">
                            <b-button class="button-remove-icon" @click="listaCampos.splice(index, 1)"
                              v-if="listaCampos.length > 1">
                              <ImgDeleteIcon></ImgDeleteIcon>
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                    </div>
                  </b-col>
                </template>
              </div>
            </div>
          </b-col>
        </template>

        <!-- Botão -->
        <template>
          <b-col class="container-btn-save d-flex justify-content-end">
            <b-form-group>
              <b-button v-if="!sendingForm" class="btn-save-object form-control" @click="createObject()">
                {{ $t('IntegrationPage.Save') }}
              </b-button>

              <b-button v-if="sendingForm" variant="primary" disabled class="form-control loading" label-for="value">
                <b-spinner small />
                {{ $t('IntegrationPage.Sending') }} ...
              </b-button>
            </b-form-group>
          </b-col>
        </template>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { TheMask } from 'vue-the-mask'
import ImgDeleteIcon from '@/assets/images/pages/icon-delete.svg'
import ImgAddIcon from '@/assets/images/pages/icon-add.svg'
import ImgEditIcon from '@/assets/images/pages/edit.svg'
import ImgDetailsIcon from '@/assets/images/pages/zoom-detail-icon.svg'
import ImgLessIcon from '@/assets/images/pages/less-icon.svg'


import {
  BAvatar, BListGroup, BListGroupItem, BCardText, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BSpinner, BRow, BCol, BFormTextarea, BModal
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    TheMask,
    flatPickr,
    ToastificationContent,
    ImgDeleteIcon,
    ImgAddIcon,
    ImgEditIcon,
    ImgDetailsIcon,
    ImgLessIcon,
    BModal
  },
  directives: {
    Ripple,
  },
  props: {
    listagemContexto: {
      type: Array,
    },
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      type: '',
      name: '',
      json: null,
      method: null,
      integratedSecurity: 0,
      sendingForm: false,
      stateName: null,
      stateMethod: null,
      invalidNameState: null,
      urlRelativa: null,
      objectReference: null,
      stateObject: null,
      jsonValid: true,
      AddFields: true,
      typeList: [
        { value: null, text: this.$t('Select'), disabled: true },
        { text: 'BIGINT', value: 'BIGINT' },
        { text: 'BINARY', value: 'BINARY' },
        { text: 'CHAR', value: 'CHAR' },
        { text: 'DATE', value: 'DATE' },
        { text: 'DATETIME', value: 'DATETIME' },
        { text: 'DATETIMEOFFSET', value: 'DATETIMEOFFSET' },
        { text: 'DECIMAL', value: 'DECIMAL' },
        { text: 'FLOAT', value: 'FLOAT' },
        { text: 'HIERARCHYID', value: 'HIERARCHYID' },
        { text: 'IMAGE', value: 'IMAGE' },
        { text: 'INT', value: 'INT' },
        { text: 'MONEY', value: 'MONEY' },
        { text: 'NCHAR', value: 'NCHAR' },
        { text: 'NTEXT', value: 'NTEXT' },
        { text: 'NUMERIC', value: 'NUMERIC' },
        { text: 'NVARCHAR', value: 'NVARCHAR' },
        { text: 'REAL', value: 'REAL' },
        { text: 'SMALLDATETIME', value: 'SMALLDATETIME' },
        { text: 'SMALLINT', value: 'SMALLINT' },
        { text: 'SMALLMONEY', value: 'SMALLMONEY' },
        { text: 'TEXT', value: 'TEXT' },
        { text: 'TIME', value: 'TIME' },
        { text: 'TIMESTAMP', value: 'TIMESTAMP' },
        { text: 'TINYINT', value: 'TINYINT' },
        { text: 'UNIQUEIDENTIFIER', value: 'UNIQUEIDENTIFIER' },
        { text: 'VARBINARY', value: 'VARBINARY' },
        { text: 'VARCHAR', value: 'VARCHAR' },
      ],
      authenticationList: [
        {
          key: '',
          value: '',
          stateKey: null,
          stateValue: null
        }
      ],
      listaCampos: [
        {
          key: '',
          value: null,
          stateKey: null,
          stateValue: null
        }
      ],
      previewDataJson: null,
      sendPreviewData: false,
      collectionReference: [],
      // Azure data facoty
      resourceGroup: null,
      dataFacotry: null,
      stateResourceGroup: null,
      stateDataFactory: null,
      statePipeline: null
    }
  },
  created() {
    this.getConnectionType();
  },
  methods: {
    atualizaJson() {
      this.$emit('atualizaJson', this.json)
    },
    atualizaPreviewData() {
      this.$emit('atualizaPreviewData', this.previewDataJson)
    },
    adicionaCampos() {
      this.listaCampos.push({ id: null, key: '', value: '', stateKey: null, stateValue: null })
    },
    newRowAuthentication() {
      this.authenticationList.push({ key: '', value: '', stateKey: null, stateValue: null })
    },
    getConnectionType() {
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/connection/type?type_id=${this.$router.currentRoute.params.type}`)
        .then(res => { this.type = res.data })
    },
    createObject() {
      this.validaCampos();
      if (this.camposValidos()) {
        this.sendingForm = true
        this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/integration/object`,
          {
            id: -1,
            connection_id: this.$router.currentRoute.params.connection_id,
            type: this.type,
            name: this.name,
            site_guid: this.currentSite.guid,
            json: this.json,
            method: this.method,
            resource_group: this.resourceGroup,
            data_factory: this.dataFacotry,
            relative_url: this.urlRelativa,
            object_path: this.objectReference,
            header: this.mountHeader(),
            object_fields: this.mountFields()
          })
          .then(response => {
            this.sendingForm = false
            this.$emit('refetch-data')
            this.$emit('show-message')
            this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', this.$t('IntegrationPage.createObjectSuccess'), 'success')
          })
          .catch((error => { this.sendingForm = false; this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger') }))
      }
    },
    mountHeader() {
      if (this.authenticationList.length > 0 && this.authenticationList[0].key && this.authenticationList[0].value) {
        let header = this.authenticationList.reduce((o, x) => ({ ...o, [x.key]: x.value }), {});
        return header
      }
    },
    mountFields() {
      let arrayFields = [];
      for (let i = 0; i < this.listaCampos.length; i++) {
        let obj = {
          id: null,
          name: this.listaCampos[i].key,
          type: this.listaCampos[i].value
        }

        if (obj.name && obj.type)
          arrayFields.push(obj);
      }

      return arrayFields;
    },
    camposValidos() {
      if (this.stateName != false) {

        if (this.type.name == 'REST') {
          if (this.method == 'GET') {
            let credenciaisValidas = []
            this.authenticationList.map(item => {
              if (item.stateKey != false && item.stateValue != false) {
                credenciaisValidas.push('true')
              }
              else {
                credenciaisValidas.push('false')
              }
            })
            if (!credenciaisValidas.includes('false')) {
              return true
            }
            else {
              return false
            }
          }

          if (this.method == 'POST' && !this.json) {
            let credenciaisValidas = []
            this.listaCampos.map(item => {
              if (item.stateKey != false && item.stateValue != false) {
                credenciaisValidas.push('true')
              }
              else {
                credenciaisValidas.push('false')
              }
            })

            if (!credenciaisValidas.includes('false')) {
              return true
            }
            else {
              return false
            }
          }
        }

        if (this.type.name == 'Data Factory') {
          if (this.stateDataFactory != false && this.stateResourceGroup != false && this.stateName != false) {
            return true
          }
        }
        else {
          return true
        }
      }
      else {
        return false
      }
    },
    setLabel() {
      let label
      switch (this.type.name) {
        case 'SQL Server':
          label = this.$t('IntegrationPage.TableNameSQL')
          break;
        case 'SAP':
          label = this.$t('IntegrationPage.ObjectRFC')
          break;
        case 'CSV':
          label = this.$t('IntegrationPage.CSVPathFile')
          break;
        case 'REST':
          label = this.$t('IntegrationPage.nameObjectAPI')
          break;
      }
      return label
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
    validaNomeFonte() {
      let fonteExistente = this.listagemContexto.filter(item => item.object == this.name);
      return fonteExistente.length > 0;
    },
    validaCampos() {
      let validaNome = this.validaNomeFonte();
      this.stateName = this.name && !validaNome ? null : false;
      this.stateMethod = this.method ? null : false;
      this.invalidNameState = validaNome;
      this.stateObject = this.objectReference ? null : false
      this.authenticationList.map(item => {
        if (item.key == '' && item.value) {
          item.stateKey = false
        }
        else {
          item.stateKey = null
        }
        if (item.value == '' && item.key) {
          item.stateValue = false
        }
        else {
          item.stateValue = null
        }
      });

      this.listaCampos.map(item => {
        if(!item.key && !item.value){
          item.stateKey = false;
          item.stateValue = false;
          return;
        }else{
          item.stateKey = null;
          item.stateValue = null;
        }

        if (!item.key && item.value) {
          item.stateKey = false
        }
        else {
          item.stateKey = null
        }

        if (!item.value && item.key) {
          item.stateValue = false
        }
        else {
          item.stateValue = null
        }
      })

      this.stateResourceGroup = this.resourceGroup ? null : false;
      this.stateDataFactory = this.dataFacotry ? null : false;
    },
    previewData() {
      this.sendPreviewData = true;

      this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/integration/object/preview`,
        {
          site_guid: this.currentSite.guid,
          method: this.method,
          connection_id: this.$router.currentRoute.params.connection_id,
          relative_url: this.urlRelativa,
          header: '',
          body: this.json
        })
        .then(response => {
          this.sendPreviewData = false;
          this.previewDataJson = response.data;
          this.atualizaPreviewData();
          let array = [{value: null, text: this.$t('Select')}];
          this.collectionReference = array.concat(this.getKeys(this.previewDataJson, ''));
        })
        .catch((error => {
          this.sendPreviewData = false;
          this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
        }))
    },
    isobject(x) {
      if ((Array.isArray(x) && x.length === 0))
        return false;

      if (typeof x === 'object' && x !== null)
        return true;

      return false;
    },
    getKeys(obj, prefix) {
      let isArray = false;

      if (Array.isArray(obj) && obj.length > 0) {
        obj = obj[0];
        isArray = true;
      }

      var keys = Object.keys(obj);
      prefix = prefix ? (isArray ? prefix + '[0].' : prefix + '.') : (isArray ? '[0].' : '');
      let ret = keys.reduce((result, key) => {
        if (this.isobject(obj[key])) {
          result.push(prefix + key);
          result = result.concat(this.getKeys(obj[key], prefix + key));
        }

        return result;
      }, []);

      return ret;
    }
  }
}
</script>

<style lang="scss" >
#input-method.custom-select,
#type-add-field.custom-select {
  background: #fff url('../../../../assets/images/pages/arrow-down-2.png') right 1rem center/10px 10px no-repeat;
}

.border-btn-visualizar {
  border: 1px solid #CFC4BE;
  padding: 16px 16px 0 16px;
  border-radius: 6px;

  .border-campos {
    margin-bottom: 16px;
    border-radius: 6px;
    background-color: #FAFAFA;
    width: 100%;
    border: 1px solid #CFC4BE;

    .object {
      margin-top: 8px;
    }

    .header-campos {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        margin: 16px 14px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #4C4541;
      }

      svg {
        margin-right: 16px;

        path {
          fill: #4C4541;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .btn-visualizar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 9px;
    width: 100%;
    border: 1px solid #974900;
    background-color: transparent;
    color: #974900;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}
</style>

<style lang="scss" scoped>
.form-group-json {
  .input-json {
    padding-right: 52px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .btn-details {
    padding: 2px 7px;
    border-radius: 5px;
    position: absolute;
    right: 29px;
    bottom: 21px;

    &:hover {
      cursor: pointer;
      background-color: #ffede2;
    }
  }
}

.container-btn-save {
  margin-top: 6px;

  .btn-save-object {
    width: 105px;
    height: 46px;
    background-color: #974900 !important;
  }
}

.btn.loading {
  width: auto !important;
  background-color: #974900 !important;
  border-color: #974900 !important;
}

#input-method.custom-select.is-invalid,
#type-add-field.custom-select.is-invalid {
  background: #fff url('../../../../assets/images/pages/arrow-down-2.png') right 1rem center/10px 10px no-repeat;
}

.is-invalid {
  background: none;
}

.object {

  .button-action {
    display: flex;
    align-items: center;

    .button-add-icon {
      padding: 9px 12px;
      border-radius: 5px;
      background-color: #974900 !important;
      border: 1px solid #974900 !important;
      margin-right: 8px;

      :hover {
        cursor: pointer;
      }
    }

    .button-remove-icon {
      padding: 9px 12px;
      border-radius: 5px;
      background-color: #D32F2F !important;
      border: 1px solid #D32F2F !important;

      :hover {
        cursor: pointer;
      }
    }
  }

  .label-key,
  .label-value {
    margin-bottom: 3px;
    color: #5e5873;
    font-size: 0.857rem;
  }

  .icon-edit-key,
  .icon-edit-value {
    margin-left: 7px;
  }
}
</style>
