<template>
    <div class="justify-content-between align-items-center content-sidebar px-1 py-1">
        <b-form>
            <label for="source-fields">{{ $t('IntegrationPage.SourceFields') }}</label>
            <div class="div-source-fields" id="source-fields">
                <span v-for="item in sourceFields" class="badge-source-field">
                    {{ item.label }}
                </span>
            </div>

            <div class="label-query">
                <span>Query</span>
                <icon-info id="tooltip-query-info" />

                <b-tooltip target="tooltip-query-info" triggers="hover blur" placement="topright"
                    custom-class="tooltip-info-query">
                    <div class="info-tooltip-query">
                        {{ $t('IntegrationPage.QueryInfo') }}
                    </div>
                </b-tooltip>
            </div>

            <b-form-group :invalid-feedback="$t('IntegrationPage.InvalidQuery')">
                <b-form-textarea v-model="form.query" :placeholder="$t('TypeHere')" rows="2" :state="queryState"
                    @blur="getParameters" />
            </b-form-group>

            <div v-if="showParams">
                <b-table class="desktop-section mb-0" :fields="fields" :items="form.params" id="table-extra-fields"
                    borderless responsive>
                    <template #cell(value)="data">
                        <b-form-group :invalid-feedback="$t('RequiredField')" class="mb-0">
                            <b-form-input v-model="data.item.value" :state="data.item.state"></b-form-input>
                        </b-form-group>
                    </template>
                </b-table>

                <div class="separator mt-1"></div>

                <div class="mobile-section" v-for="(item, index) in form.params">
                    <div class="d-flex justify-content-between mb-1">
                        <span class="prop-name">{{ $t('ResourceStatus.Name') }}</span>
                        <span class="prop-value">{{ item.name }}</span>
                    </div>

                    <div class="d-flex justify-content-between mb-1">
                        <span class="prop-name">{{ $t('IntegrationPage.Type') }}</span>
                        <span class="prop-value">{{ item.type }}</span>
                    </div>

                    <div class="d-flex justify-content-between">
                        <span class="prop-name w-50">{{ $t('IntegrationPage.ValueRequired') }}</span>
                        <b-form-group :invalid-feedback="$t('RequiredField')" class="form-group-extra-field">
                            <b-form-input v-model="item.value" :state="item.state"></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="separator"></div>
                </div>

                <div class="separator sp-mobile"></div>
            </div>

            <b-button @click="validateQuery" class="valid d-flex justify-content-center" :disabled="!form.query">
                {{ $t('Validate') }}
            </b-button>

            <b-button @click="onSubmit" variant="primary" class="form-control btn-save-schema mt-2 mb-2">
                {{ $t('IntegrationPage.Save') }}
            </b-button>
        </b-form>
    </div>
</template>

<script lang="js">
import Ripple from 'vue-ripple-directive';
import VSelectChips from '@/@core/components/v-select/vSelectChips.vue';
import IconInfo from '@/assets/images/icons/info.svg';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import {
    BAvatar, BListGroup, BListGroupItem, BCardText, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BSpinner, BRow, BCol, BFormTextarea, BTooltip, BModal, BTable
} from 'bootstrap-vue'

export default {
    components: {
        BAvatar,
        BListGroup,
        BListGroupItem,
        BCardText,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BButton,
        BSpinner,
        BRow,
        BCol,
        BFormTextarea,
        VSelectChips,
        IconInfo,
        BTooltip,
        BModal,
        BTable,
        ToastificationContent
    },
    directives: {
        Ripple,
    },
    props: {
        object: {
            type: Object,
        },
    },
    mounted() {
        this.getSourceFields();
        this.getLastQuery();
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            sourceFields: [],
            fields: [
                {
                    key: 'name',
                    label: this.$t('ResourceStatus.Name'),
                    sortable: false,
                    class: 'field-label'
                },
                {
                    key: 'type',
                    label: this.$t('IntegrationPage.Type'),
                    sortable: false,
                    class: 'field-label'
                },
                {
                    key: 'value',
                    label: this.$t('IntegrationPage.ValueRequired'),
                    sortable: false,
                },
            ],
            showParams: false,
            queryState: null,
            form: {
                query: '',
                params: []
            }
        }
    },
    methods: {
        getSourceFields() {
            this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/integration/object-available?site_guid=${this.currentSite.guid}&object_id=${this.object.id}`, this.form)
                .then(response => {
                    response.data.forEach((item) => {
                        this.sourceFields.push(
                            {
                                label: `${item.field_name} (${item.field_type})`
                            }
                        );
                    });
                })
                .catch((error => {
                    this.sendingForm = false;
                    this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
                }))
        },
        getLastQuery() {
            this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/integration/object/query/${this.object.id}`)
                .then(response => {
                    this.form.query = response.data.query;
                })
                .catch((error => {
                    this.sendingForm = false;
                    this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
                }))
        },
        validateQuery() {
            if (this.form.query && this.checkParameters()) {
                this.sendingForm = true;
                let query = this.form.query;

                let obj = {
                    params: this.form.params,
                    query: query
                }

                this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/integration/object/check`, obj)
                    .then(response => {
                        if (response.data && response.data.length == 0) {
                            this.queryState = false;

                            let object = {
                                data: this.$t('NoResultsFound'),
                                valid: false
                            }
                            this.$emit('validade-query', object);
                        } else {
                            let ret = response.data[0];

                            if (ret.hasOwnProperty("")) {
                                this.queryState = false;

                                let object = {
                                    data: this.$t('IntegrationPage.NoColumnName'),
                                    valid: false
                                }
                                this.$emit('validade-query', object);
                            } else {
                                this.queryState = null;
                                this.sendingForm = false;
                                let object = {
                                    data: response.data,
                                    valid: true
                                }
                                this.$emit('validade-query', object);
                            }
                        }
                    })
                    .catch((error => {
                        this.queryState = false;
                        this.sendingForm = false;
                        let object = {
                            data: error.response.data.message,
                            valid: false
                        }
                        this.$emit('validade-query', object);
                    }))
            }
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    text: text,
                    variant,
                },
            })
        },
        getParameters() {
            let regex = /(?<=\{)([^{}]+)(?=\})/g;
            let matches = this.form.query.match(regex);

            if (matches && matches.length > 0) {
                let arrayParams = [];
                matches.forEach((param) => {
                    let existParam = this.form.params.find(f => f.name === param);
                    let existLocalParam = arrayParams.find(f => f.name === param);

                    if (!existLocalParam) {
                        arrayParams.push(
                            {
                                name: param,
                                type: 'string',
                                value: existParam ? existParam.value : '',
                                state: null
                            }
                        )
                    }
                });
                this.form.params = arrayParams;
                this.showParams = true;
                this.queryState = null;
            }
            else
                this.showParams = false;
        },
        checkParameters() {
            let regex = /(?<=\{)([^{}]+)(?=\})/g;
            let matches = this.form.query.match(regex);
            let valid = true;
            let uniqueMatches = [...new Set(matches)];

            if (uniqueMatches.length > 0) {
                if (uniqueMatches.length !== this.form.params.length) {
                    this.getParameters();
                    return false;
                }

                uniqueMatches.forEach((item) => {
                    let param = this.form.params.find(f => f.name === item);

                    if (!param || !param.value) {
                        valid = false;
                        param.state = false;
                        return;
                    }

                    param.state = null;
                });
            }

            return valid;
        },
        onSubmit() {
            if (this.form.query) {
                if (this.form.query && this.checkParameters()) {
                    this.sendingForm = true;

                    let obj = {
                        params: this.form.params,
                        query: this.form.query,
                        site_guid: ''
                    }

                    this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/integration/object/check`, obj)
                        .then(response => {
                            if (response.data && response.data.length == 0) {
                                this.queryState = false;

                                let object = {
                                    data: this.$t('NoResultsFound'),
                                    valid: false
                                }
                                this.$emit('validade-query', object);
                            } else {
                                let ret = response.data[0];

                                if (ret.hasOwnProperty("")) {
                                    this.queryState = false;

                                    let object = {
                                        data: this.$t('IntegrationPage.NoColumnName'),
                                        valid: false
                                    }
                                    this.$emit('validade-query', object);
                                } else {
                                    this.queryState = null;
                                    obj.site_guid = this.currentSite.guid;

                                    this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/integration/object/query/save/${this.object.id}`, obj)
                                        .then(response => {
                                            if (response.status == 200) {
                                                this.showToast(this.$t('RefuseStatus.Success'), 'FrownIcon', this.$t('SchemaChangeSuccess'), 'success');
                                                this.$emit('close-edit-modal');
                                            }
                                        })
                                        .catch((error => {
                                            this.queryState = false;
                                            this.sendingForm = false;
                                            this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', error.response.data.message, 'danger')
                                        }))
                                }
                            }
                        })
                        .catch((error => {
                            this.queryState = false;
                            this.sendingForm = false;
                            let object = {
                                data: error.response.data.message,
                                valid: false
                            }
                            this.$emit('validade-query', object);
                        }))
                }
            } else {
                this.$emit('close-edit-modal');
            }
        }
    }
}
</script>

<style lang="scss">
.form-group-extra-field {
    width: 140px !important;
    margin-bottom: 0 !important;
}

.mobile-section {
    display: none;
}

.desktop-section {
    display: block;
}

.sp-mobile {
    display: none;
}

#table-extra-fields {
    thead th {
        width: 1%;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #998F8A;
        outline: none;
        border-top: 0;
        background-color: #FFFFFF !important;
        border-bottom: 1px solid #CFC4BE !important;
        text-transform: uppercase;
        padding: 0.72rem 0rem !important;

        .dark-layout & {
            background-color: #283046 !important;
            outline-color: #3b4253 !important;
        }

        &.col-name-type {
            width: 40%;
        }
    }

    tbody td {
        border: none !important;
        padding: 0.72rem 0rem !important;

        &.field-label {
            font-weight: 600;
            font-size: 14px;
            color: #4C4541;
        }

    }
}

.tooltip-info-query {
    //não adicione padding aqui e só estilize as classes especificas se possível
    margin: 0;

    .tooltip-inner {
        padding: 0;
        max-width: 100%;
        min-width: 1%;
        border-radius: 6px;
        overflow: hidden;

        .info-tooltip-query {
            padding: 8px;
            text-align: left;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            max-width: 345px;
        }
    }

    .arrow {
        &::before {
            border-top-color: #323232;
            border-bottom-color: #323232;
        }
    }
}

.prop-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #998F8A;
}

.prop-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #4C4541;
}

.div-source-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    border: 1px solid #CFC4BE;
    border-radius: 5px;
    padding: 10px;

    .badge-source-field {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #974900;
        border-radius: 5px;
        padding: 2px 5px;
        color: #FFFFFF;
        font-size: 13px;
        line-height: 20px;
    }
}

.label-query {
    display: flex;
    gap: 5px;
    font-size: 0.857rem;
    margin-top: 1rem;
    margin-bottom: 0.2857rem;
}

.valid {
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF !important;
    color: #974900 !important;
    border-radius: 6px;
    border: 1px solid #974900 !important;
    font-size: 12px;
    font-weight: 600;

    &:hover {
        background-color: #ffede1 !important;
        box-shadow: none !important;
    }

    &:focus {
        background-color: transparent !important;
    }
}

.btn-save-schema {
    width: 105px;
    height: 46px;
    background-color: #974900 !important;
    border: none !important;
    float: right;
    font-size: 17px;
    font-weight: 600;

    &:focus {
        background-color: #974900 !important;
    }
}

.separator {
    height: 1px;
    background: #CFC4BE;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

@media (max-width: 480px) {
    .tooltip-info-query {
        .tooltip-inner {
            .info-tooltip-query {
                max-width: 229px !important;
            }
        }
    }

    .mobile-section {
        display: block;
    }

    .desktop-section {
        display: none;
    }
}
</style>
